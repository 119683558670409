import React from 'react'
import { ui } from '../../graphql/posts'

const PaginatedPosts = ({ posts }) => {
  return posts?.length > 0
    ? posts.map(post => <ui.Entry key={post?.id} model={post} />)
    : null
}

export default PaginatedPosts
