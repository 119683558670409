import { request } from '../../api/actions'
import { postNotification } from '../../notify/actions'

const INVITE_SUCCESS = 'entities/invite_email.INVITE_SUCCESS'
const INVITE_FAILURE = 'entities/invite_email.INVITE_FAILURE'

export default function invite(email, message, url, resource, isMedShrOpen) {
  const api_url = '/api/profile/email-invite'
  return request({
    url: api_url,
    id: api_url,
    method: 'POST',
    json: {
      email,
      message,
      url,
      resource: resource ? { type: resource.type, id: resource.id } : undefined,
      isMedShrOpen,
    },
    success: [
      {
        type: INVITE_SUCCESS,
      },
      postNotification({
        kind: 'info',
        id: 'invite_email.invite_sent',
      }),
    ],
    failure: {
      type: INVITE_FAILURE,
      notification: {
        id: 'invite_email.invite_not_sent',
        kind: 'error',
      },
    },
  })
}
