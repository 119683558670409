import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React from 'react'

import { Item } from '../../graphql/posts'
import BlogMeta from './BlogMeta'
import PaginatedPosts from './PaginatedPosts'

const BlogListing = ({ title, posts }: { title: string; posts: Item[] }) => {
  const router = useRouter()
  const { t } = useTranslation('blog')

  let description

  if (router.pathname === '/covid') {
    description = t('blog.covid_listing_page_sub_heading')
  } else if (router.pathname === '/news') {
    description = t('blog.listing_page_sub_heading')
  } else {
    description = t('blog.listing_page_sub_heading')
  }

  return (
    <>
      <BlogMeta title={title} description={description} />
      <div className="posts">
        <div className="posts__content">
          <PaginatedPosts posts={posts} />
        </div>
      </div>
    </>
  )
}

export default BlogListing
