import debounce from 'lodash/debounce'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React, { useState } from 'react'

import BlogListing from '../components/Blog/List'
import { usePublicPostsFeed } from '../graphql/posts'
import { useIntersectWithClick } from '../lib/useIntersectWithClick'
import { withResourcePageSearchBar } from '../routes/components/search'
import Button from '../ui/buttons/button'

const inlineStyles = {
  bannerPageContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  bannerDotsImage: {
    background: 'none',
  },
  bannerTextWrapper: {
    marginLeft: 20,
  },
  bannerH1: {
    margin: 0,
  },
  bannerH5: {
    maxWidth: 300,
    margin: 0,
    paddingRight: 10,
  },
}

const pageSize = 9

const BlogListingPage = () => {
  const [offset, setOffset] = useState(0)
  const [setNode] = useIntersectWithClick({})

  const { t } = useTranslation('blog')

  const { error, data, fetchMore, networkStatus } = usePublicPostsFeed({
    variables: {
      offset: offset,
      limit: pageSize,
    },
    notifyOnNetworkStatusChange: true,
  })

  const nextPage = () => {
    setOffset(offset + pageSize)
    void fetchMore({
      variables: {
        offset: offset,
      },
    })
  }

  const debouncedNextPage = debounce(nextPage, 300, {
    leading: true,
    trailing: false,
  })

  const handleClick = () => debouncedNextPage()

  const count = data?.count?.aggregate?.count ?? 0
  const hasNextPage = offset + pageSize <= count
  const isRefetching = networkStatus === 3

  if (error) {
    return <div>Error</div>
  }

  if (networkStatus === 1) {
    return <div>Loading...</div>
  }

  return (
    <div className="fs-unmask">
      <div className="posts__banner">
        <div className="page__content" style={inlineStyles.bannerPageContent}>
          <img
            src="/static/media/images/blog-side-dots.png"
            style={inlineStyles.bannerDotsImage}
          />

          <div style={inlineStyles.bannerTextWrapper}>
            <h1 style={inlineStyles.bannerH1}>
              {t('blog.listing_page_heading')}
            </h1>
            <h5 style={inlineStyles.bannerH5}>
              {t('blog.listing_page_sub_heading')}
            </h5>
          </div>
        </div>
      </div>
      <div className="page__content">
        <div id="postList">
          {data?.posts?.length > 0 ? (
            <BlogListing posts={data.posts} title="MedShr Open" />
          ) : (
            <div>
              <p>No posts to show</p>
            </div>
          )}
          <div>
            <Button
              wide
              secondary
              id="buttonLoadMore"
              disabled={isRefetching || !hasNextPage}
              onClick={handleClick}
              reference={setNode}
            >
              Load more
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'blog'])),
    },
  }
}

export default withResourcePageSearchBar(BlogListingPage)
