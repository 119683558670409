import cs from 'classnames'
import React, { useEffect, useState } from 'react'
import { isNativeAppBrowser } from '../lib/client-info'
import { linkGenerators, windowOpen } from '../lib/SocialLinkGenerator'

// /////////////////////////////////////////////////////////////////////// UTILS
import { selectAuthenticatedUser } from '../modules/auth/selectors'

// ////////////////////////////////////////////////////////////////// COMPONENTS
import { getBranch } from '../modules/branch'
import { qualifyUrl } from '../url'

// ///////////////////////////////////////////////////////// ACTIONS & SELECTORS
import { openModal } from '../modules/modal/actions'
import { useDispatch, useSelector } from '../react-redux'
import { IconButton } from './buttons'
import EmailInviteModal from './email-invite-modal'
import { Email, Facebook, LinkedIn, MoreIcon, Twitter } from './icons'
import { BasicListItem } from './Lists'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type PropsType = {
  url: string
  feature: string
  title: string
  resource?: any
  theme?: Theme
  align?: Align
  isMedShrOpen?: boolean
  className?: string
}

type Theme = 'dark' | 'light'
type Align = 'right' | 'left' | 'center'

const SocialShare = ({
  url,
  feature,
  title,
  resource,
  theme = 'dark',
  align = 'left',
  isMedShrOpen = false,
  className,
}: PropsType) => {
  theme = theme ?? 'dark'
  align = align ?? 'left'
  isMedShrOpen = isMedShrOpen ?? false

  const dispatch = useDispatch()
  const isNative = isNativeAppBrowser()
  const user = useSelector(selectAuthenticatedUser)
  const isAuthed = !!user
  const verificationStatus = user ? user.verification_status : 'unconfirmed'

  const promiseBranchURL = network =>
    getBranch().then(branch =>
      branch.link(getBranchParams({ url, feature, title, resource, network }))
    )

  const socialSharePopup = network => {
    if (isNative) {
      window.location.href = linkGenerators[network](url, { title })
    } else {
      if (network === 'twitter') {
        windowOpen(linkGenerators[network](url, { title }))
      } else {
        windowOpen(
          promiseBranchURL(network).then(url =>
            linkGenerators[network](url, { title })
          )
        )
      }
    }
  }

  const socialLinks: any[] = [
    {
      name: 'linkedin',
      button: <LinkedIn width={13} />,
      onClick: () => socialSharePopup('linkedin'),
    },
    {
      name: 'facebook',
      button: <Facebook width={8} />,
      onClick: () => socialSharePopup('facebook'),
    },
    {
      name: 'twitter',
      button: <Twitter width={16} />,
      onClick: () => socialSharePopup('twitter'),
    },
  ]
  if (isAuthed && verificationStatus !== 'unverified') {
    socialLinks.push({
      name: 'email',
      button: <Email width={13} />,
      onClick: () =>
        promiseBranchURL('email').then(url =>
          dispatch(
            openModal({
              ...EmailInviteModal,
              componentProps: { url, resource, feature, isMedShrOpen },
            })
          )
        ),
    })
  }

  return (
    <ul className={cs('social-share', className)}>
      {socialLinks.map(item => (
        <BasicListItem
          side={align}
          key={`${item.name}`}
          className="p-2-2-2-2 social-icon-list"
        >
          <IconButton onClick={item.onClick} theme={theme}>
            {item.button}
          </IconButton>
        </BasicListItem>
      ))}
      {typeof navigator !== 'undefined' &&
      typeof navigator.share !== 'undefined' &&
      isNative ? (
        <BasicListItem side={align} className="p-2-2-2-2 social-icon-list">
          <WebShareButton
            url={url}
            feature={feature}
            title={title}
            resource={resource}
            theme={theme}
          />
        </BasicListItem>
      ) : null}
    </ul>
  )
}

const getBranchParams = ({ url, feature, title, resource, network }) => {
  const params: any = {
    channel: network,
    '~channel': network,
    feature: feature,
    '~feature': feature,
    $desktop_url: url,
    $fallback_url: url,
  }

  if (resource) {
    params.id = resource.id
    params.type = resource.type

    //Special case for the cases
    if (resource.type === 'learning_module') {
      params.screen_id = 'web_page'
      params.url = url
    } else if (resource.type === 'case' && resource.prepoll_case_id) {
      params.screen_id = 'web_page'
      params.url = qualifyUrl('/auth/check?next=' + encodeURIComponent(url))
    } else if (resource.type === 'case') params.screen_id = 'resource'
    else params.screen_id = resource.type
  }

  return params
}

const WebShareButton = ({ url, feature, title, resource, theme }) => {
  const params = getBranchParams({
    url,
    feature,
    title,
    resource,
    network: 'web-share',
  })
  const [webShareURL, setWebShareURL] = useState(url)
  useEffect(() => {
    getBranch()
      .then(branch => branch.link(params))
      .then(setWebShareURL)
  }, [url])

  const onClick = () =>
    (webShareURL
      ? Promise.resolve(webShareURL)
      : getBranch().then(branch => branch.longLink(params))
    ).then(url =>
      typeof navigator !== 'undefined' &&
      typeof navigator['share'] !== 'undefined'
        ? navigator['share']({
            url,
            text: title,
          })
        : undefined
    )

  return (
    <IconButton onClick={onClick} theme={theme}>
      <MoreIcon width={16} />
    </IconButton>
  )
}

export default SocialShare
