import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import { closeModal } from '../modules/modal/actions'
import sendEmailInvite from '../modules/entities/actions/invite_email'
import * as form from '../ui/forms/base'
import Button from '../ui/buttons/button'

interface Props {
  sendEmail: (to, message, url, resource) => any
  url: string
  resource?: any
  feature: string
  isMedShrOpen: boolean
}

// TODO: Test still works after refactor

const EmailInvite = ({
  sendEmail,
  url,
  resource,
  feature,
  isMedShrOpen,
}: Props) => {
  const { t } = useTranslation()

  const [error, setError] = useState<string>()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState<string | number | readonly string[]>(
    resource
      ? t(
          `common:invite_email.default_message_${
            resource.created_as === 'poll' ? 'poll' : resource.type
          }`,
          {
            title: resource.title,
            url: url,
          }
        )
      : isMedShrOpen
      ? t('common:invite_email.default_message_medshr_open', { url: url })
      : t('common:invite_email.default_message', { url: url })
  )

  const onChangeEmail = e => {
    setEmail(e.target.value)
  }

  const onChangeMessage = e => {
    setMessage(e.target.value)
  }

  const onSendEmail = () => {
    if (/.+@.+/.test(email)) {
      sendEmail(email, message, url, resource)
    } else {
      setError('Please enter an email address')
    }
  }

  const renderError = () => {
    if (error) {
      return <form.InlineErrorMessage error={error} touched />
    }
  }

  return (
    <div>
      <div className="modal__header">
        <h1>
          {feature === 'share'
            ? t('common:invite_email.dialog_title_share')
            : t('common:invite_email.dialog_title')}
        </h1>
      </div>
      <div className="email-invite__content">
        <form.Field label={t('common:invite_email.to_field_label')}>
          <form.Input
            type="email"
            id="invite_email-email"
            value={email}
            onChange={onChangeEmail}
            autoFocus={0 === email.length}
          />
          {renderError()}
        </form.Field>
        <form.Field label={t('common:invite_email.message_field_label')}>
          <textarea
            id="invite_email-message"
            value={message}
            onChange={onChangeMessage}
          />
        </form.Field>
        <Button wide className="pull-right" onClick={onSendEmail}>
          {t('common:invite_email.send_button_label')}
        </Button>
      </div>
    </div>
  )
}

const EmailInviteModal = {
  component: connect(null, mapDispatchToProps)(EmailInvite),
  className: 'fs-unmask modal--centered modal--dialog modal--email-invite',
  id: 'email-invite',
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    sendEmail: (to, message, url, resource) => {
      dispatch(
        sendEmailInvite(to, message, url, resource, ownProps.isMedShrOpen)
      )
      dispatch(closeModal())
    },
  }
}

export default EmailInviteModal
