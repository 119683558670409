import React, { useEffect, useRef, useState } from 'react'

interface Props {
  root?: Element | Document
  rootMargin?: string
  threshold?: number | number[]
}

export const useIntersectWithClick = ({
  root = null,
  rootMargin = '0px',
  threshold = 1,
}: Props): [React.Dispatch<any>, any] => {
  const [entry, setEntry] = useState(null)
  const [node, setNode] = useState(null)
  const observer = useRef(null)

  useEffect(() => {
    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEntry(entry)
          const target = entry.target as HTMLButtonElement
          target.click()
        }
      },
      {
        root,
        rootMargin,
        threshold,
      }
    )

    const { current: currentObserver } = observer
    if (node) currentObserver.observe(node)
    return () => currentObserver.disconnect()
  }, [node, root, rootMargin, threshold])

  return [setNode, entry]
}
