import platform from 'platform'

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

const linkGenerators = {
  twitter: (url, { title, via, hashtags = [] }) => {
    return `https://twitter.com/share${objectToGetParams({
      url,
      text: title,
      via,
      hashtags: hashtags.join(','),
    })}`
  },

  facebook: (url, { title, description }) => {
    return `https://facebook.com/sharer.php${objectToGetParams({
      u: url,
      title,
      description,
    })}`
  },

  linkedin: (url, { title }) => {
    return `https://linkedin.com/shareArticle${objectToGetParams({
      url,
      title,
    })}`
  },
}

function objectToGetParams(object) {
  const param = Object.keys(object)
    .filter(key => !!object[key])
    .map(key => `${key}=${encodeURIComponent(object[key])}`)
    .join('&')

  return `?${param}`
}

function windowOpen(
  url: string | Promise<string>,
  name = 'socialLink',
  height = 400,
  width = 550
) {
  const {
    outerWidth,
    outerHeight,
    screenX,
    screenY,
    screenLeft,
    screenTop,
  } = window
  const left = outerWidth / 2 + (screenX || screenLeft || 0) - width / 2
  const top = outerHeight / 2 + (screenY || screenTop || 0) - height / 2
  const config = {
    height,
    width,
    left,
    top,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
  }
  const strWindowFeatures = Object.keys(config)
    .map(key => `${key}=${config[key]}`)
    .join(', ')
  const windowName =
    platform.name === 'IE' && parseInt(platform.version, 10) < 10 ? '' : name

  if (typeof url === 'string') {
    return window.open(url, windowName, strWindowFeatures)
  }
  const w = window.open('', windowName, strWindowFeatures)
  url.then(url => (w.location.href = url))
  return w
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////////////// EXPORT
export { windowOpen, linkGenerators, objectToGetParams }
